var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"files"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"files","extra-menu-items":[_vm.ExtraSettingsMenuItems.CommonRules, _vm.ExtraSettingsMenuItems.VirtualNew]},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings"},[_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('black-white-list-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'files_extension_list',
              'validation': 'required',
              'hasAnchor': true
            }
          }}}),_c('black-white-list-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'files_mime_type_list',
              'validation': 'required',
              'hasAnchor': true
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'files_min_file_size',
              'min': 0,
              'max': Number.MAX_SAFE_INTEGER,
              'hasAnchor': true
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'files_max_file_size',
              'min': 0,
              'max': Number.MAX_SAFE_INTEGER,
              'hasAnchor': true
            }
          }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"files","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"files","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,4127453472)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"files","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,1314809037)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }